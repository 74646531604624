import * as React from 'react';
import { Content, Header, Layout, Wrapper, SectionTitle } from '../../components';
import Helmet from 'react-helmet';
import config from '../../../config/SiteConfig';
import { Link } from 'gatsby';
import { useIdentityContext } from 'react-netlify-identity';
import DashboardComponent from '../../components/Dashboard/Dashboard';

export default function Dashboard(path: { path: string }) {
  const identity = useIdentityContext();
  return (
    <Layout>
      <Helmet title={`Profile`} />
      <Header>
        <Link to="/">{config.siteTitle}</Link>
        <SectionTitle>Profile</SectionTitle>
      </Header>
      <Wrapper>
        <Content>
          <DashboardComponent />
        </Content>
      </Wrapper>
    </Layout>
  );
}
